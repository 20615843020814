/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";

import { HashLink as HLink } from "react-router-hash-link";

class Header extends Component {
  render() {
    let imgattr = "image";

    return (
      <div>
        <header id="home">
          <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header">
            <div className="container">
              <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                <img
                  style={{ height: "30px" }}
                  src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                  alt={imgattr}
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCodeply"
              >
                <i className="icofont-navigation-menu"></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarCodeply">
                <ul className="nav navbar-nav ml-auto">
                  <li>
                    <HLink className="nav-link" smooth to={`/#home`}>
                      Home
                    </HLink>{" "}
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#feature`}>
                      Feature
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#services`}>
                      About
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#screenshots`}>
                      Screenshot
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#pricing`}>
                      Price
                    </HLink>
                  </li>
                  <li>
                    <HLink className="nav-link" smooth to={`/#testimonials`}>
                      Testimonial
                    </HLink>
                  </li>

                  <li>
                    <HLink className="nav-link" smooth to={`/#contact`}>
                      Contact
                    </HLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
