import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";

import Footerdata from "./data/footerdata.json";

import Home from "./components/Home";
import Header from "./components/components-global/Header";
import Footer from "./components/components-global/Footer";

import ScrollToTop from "./components/components-global/ScrollToTop";

class Root extends Component {
  render() {
    return (
      <div>
        <Router>
          <HashRouter basename="/">
            <div>
              <Header />
              <ScrollToTop />
              <Switch>
                <Route path="/" exact component={Home} />
              </Switch>
              <Footer data={Footerdata} />
            </div>
          </HashRouter>
        </Router>
      </div>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
