import React from "react";

import Contact from "./components-home/Contact";
import Feature from "./components-home/Feature";
import FeatureOne from "./components-home/FeatureOne";
import FeatureTwo from "./components-home/FeatureTwo";
import Heroarea from "./components-home/Heroarea";
import Pricing from "./components-home/Pricing";
import Testimonials from "./components-home/Testimonials";

import Preface from "./components-home/Preface";

import Screenshots from "./components-home/Screenshots";

const Home = () => {
  return (
    <div>
      <Heroarea />
      <Feature />
      <FeatureOne />
      <FeatureTwo />

      <Screenshots />
      <Preface />
      <Pricing />
      <Testimonials />

      <Contact />
    </div>
  );
};

export default Home;
