import React from "react";
import homedata from "../../data/homedata.json";
import { Typewriter } from "react-simple-typewriter";

const FeatureOne = () => {
  let publicUrl = process.env.PUBLIC_URL;

  const handleDone = () => {
    // Your onLoopDone logic here
  };

  const handleType = () => {
    // Your onType logic here
  };

  return (
    <section id="feature1" className="p-100px-tb featured-1 position-relative">
      <div className="bordered-circle2"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6 md-m-50px-b">
            <img
              className="moveUpDown"
              src={publicUrl + homedata.feature1.imgurl1}
              alt=""
            />
          </div>
          <div className="col-sm-6">
            <div style={{ height: "200px" }}>
              <h2 style={{ fontWeight: 700, fontSize: "46px" }}>
                <Typewriter
                  words={["Remote Dev: Mobile App Code Editor On the Go"]}
                  loop={0}
                  cursor
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={20}
                  delaySpeed={1000}
                  onLoopDone={handleDone}
                  onType={handleType}
                />
              </h2>
            </div>
            <p>{homedata.feature1.content}</p>
            <a
              href={homedata.feature1.btn1url}
              className="btn btn-default btn-filled animated-btn"
            >
              {homedata.feature1.btn1label}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureOne;
